

import React from 'react'
import LandingNav from '../Landing-Nav/LandingNav'
import wallpaper from '../../../Components/Assets/my-yathra/company-img.jpeg'
import './about.css'

function About() {
    return (
        <div className="about-container">
            <LandingNav />

            <div className="image-container">
                <img src={wallpaper} alt="About Us" className="about-image" />
            </div>

            <div className="about-content">
                Welcome to <strong>MY YATHRA</strong> the journey begins <strong>22-07-2018</strong>. We are a passionate team of travel enthusiasts dedicated to making your travel dreams come true.
                Whether you're planning a relaxing beach vacation, an adventurous trek, or a cultural city tour, we’re here to design personalized travel experiences that suit your style,
                preferences, and budget. With 07 years of expertise in the travel industry, we pride ourselves on offering exceptional customer service and expert advice. 
                We work with trusted partners around the world to ensure that every aspect of your trip is seamless, from transportation to accommodation, activities, and beyond. 
                At <strong>MY YATHRA</strong> we believe that travel is not just about the destination, but about creating memories, exploring new cultures, and connecting with the world 
                in meaningful ways. Our goal is to make every journey special, whether it’s your first adventure or your hundredth. Let us take care of the details while you focus on the excitement ahead.
            </div>
        </div>
    )
}

export default About
