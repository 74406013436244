// import React from 'react'
// import LandingNav from '../Landing-Nav/LandingNav'

// function BookNow() {


//   const WhatsAppNumber = "9539 77 1 777"; // Add the phone number here if available


//   const handleWhatsApp = () => {
//     if (WhatsAppNumber) {
//       const formattedPhoneNumber = WhatsAppNumber.replace(/\s+/g, '');
//       const message = encodeURIComponent("Hello! I am contacting you regarding your trip.");
//       const whatsappUrl = `https://wa.me/${formattedPhoneNumber}?text=${message}`;
//       window.open(whatsappUrl, '_blank');
//     } else {
//       console.warn("WhatsApp phone number not available");
//     }
//   };


//   return (
//     <div>
//         <LandingNav/>
//         BookNow


        
        
//     </div>
//   )
// }

// export default BookNow


import React from 'react'
import LandingNav from '../Landing-Nav/LandingNav'

function BookNow() {
  const WhatsAppNumber = "9539 77 1 777"; // Your WhatsApp number

  const handleWhatsApp = () => {
    if (WhatsAppNumber) {
      const formattedPhoneNumber = WhatsAppNumber.replace(/\s+/g, '');
      const message = encodeURIComponent("Hello! I am contacting you regarding your trip.");
      const whatsappUrl = `https://wa.me/${formattedPhoneNumber}?text=${message}`;
      window.open(whatsappUrl, '_blank');
    } else {
      console.warn("WhatsApp phone number not available");
    }
  };

  return (
    <div>
      <LandingNav />
      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        <h2>Book Now</h2>
        <button 
          onClick={handleWhatsApp} 
          style={{
            padding: '12px 24px',
            backgroundColor: '#25D366',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            fontSize: '16px',
            cursor: 'pointer',
            marginTop: '20px'
          }}
        >
          Contact on WhatsApp
        </button>
      </div>
    </div>
  );
}

export default BookNow;
