

import React from 'react'
import LandingNav from '../Landing-Nav/LandingNav'
import wallpaper from '../../../Components/Assets/my-yathra/image-01.jpeg'
import '../About/about.css'

function WhyUs() {
    return (
        <div className="about-container">
            <LandingNav />

            <div className="image-container">
                <img src={wallpaper} alt="About Us" className="about-image" />
            </div>

            <div className="about-content">
            To make some beautiful moments in life memorable , as one aiming you there will always be MY YATHRA by your side . Travel makes life beautiful . To directly witness the scenic beauty of kerala , The multicultural diversity that india has to offer and the world with your eyes and not just the things you hear about along with your FRIENDS , FAMILY and LOVED ONES ones to create the happy and the enjoyable moments in your memory lifelong ...
            </div>
        </div>
    )
}

export default WhyUs