
import React, { useEffect, useState } from 'react';
import './landingnav.css';
// import logo from '../../Assets/my-yathra/logo.jpeg';
import logo from '../../Assets/my-yathra/Artboard 26@4x-8 (1).png';

import Call from '../../Assets/my-yathra/icons8-call-24.png';
import mail from '../../Assets/my-yathra/icons8-mail-35 (2).png';

import HamburgerMenu from '../../Assets/my-yathra/icons8-hamburger-menu-24.png';
import { useNavigate } from 'react-router-dom';
import instagram from '../../Assets/my-yathra/icons8-instagram-35.png';
import FaceBooklogo from '../../Assets/my-yathra/icons8-facebook-35.png';
import whatsapp from '../../Assets/my-yathra/icons8-whatsapp-35.png';
// import { GetTrips } from '../../../Api/Trips';
// import Loading from '../../Loading/Loading';
import location from '../../Assets/my-yathra/icons8-location-35 (2).png';

function LandingNav() {
  const navigate = useNavigate();
  // const [allTrips, setAllTrips] = useState([]);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchTrips = async () => {
  //     try {
  //       const tripData = await GetTrips();
  //       setAllTrips(tripData || []);
  //     } catch (error) {
  //       console.error("Error fetching notifications:", error);

  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchTrips();
  // }, []);

  // if (loading) {
  //   return <Loading />;
  // }



  // Utility function to safely access properties
  // const getTripProperty = (key) => allTrips[0]?.CreatedBy?.[key] ?? null;
  const FaceBook = "https://www.facebook.com/myyathra.in/";
  const Instagram = "https://www.instagram.com/myyathra.in/?hl=en";
  const WhatsAppNumber = "9539 77 1 777"; // Add the phone number here if available
  const mailId = "office@myyathra.in"; // Add the phone number here if available

  const LocationURL = "https://maps.app.goo.gl/vadmQUGPhzJgrD916/";

  const handleFaceBook = () => {
    if (FaceBook) {
      window.open(FaceBook, '_blank');
    } else {
      console.warn("Facebook URL not available");
    }
  };

  const handleInstagram = () => {
    if (Instagram) {
      window.open(Instagram, '_blank');
    } else {
      console.warn("Instagram URL not available");
    }
  };

  const handleLocation = () => {
    window.open(LocationURL, '_blank');
  };

  const handleWhatsApp = () => {
    if (WhatsAppNumber) {
      const formattedPhoneNumber = WhatsAppNumber.replace(/\s+/g, '');
      const message = encodeURIComponent("Hello! I am contacting you regarding your trip.");
      const whatsappUrl = `https://wa.me/${formattedPhoneNumber}?text=${message}`;
      window.open(whatsappUrl, '_blank');
    } else {
      console.warn("WhatsApp phone number not available");
    }
  };

  // const handleFaceBook = () => {
  //   const facebookUrl = getTripProperty('FaceBook');
  //   if (facebookUrl) {
  //     window.open(facebookUrl, '_blank');
  //   } else {
  //     console.warn("Facebook URL not available");
  //   }
  // };

  // const handleInstagram = () => {
  //   const instagramUrl = getTripProperty('Instagram');
  //   if (instagramUrl) {
  //     window.open(instagramUrl, '_blank');
  //   } else {
  //     console.warn("Instagram URL not available");
  //   }
  // };

  // const handleLocation = () => {
  //   window.open('https://maps.app.goo.gl/vadmQUGPhzJgrD916/', '_blank');
  // };

  // const handleWhatsApp = () => {
  //   const phoneNumber = getTripProperty('Phonenumber');
  //   if (phoneNumber) {
  //     const formattedPhoneNumber = phoneNumber.replace(/\s+/g, '');
  //     const message = encodeURIComponent("Hello! I am contacting you regarding your trip.");
  //     const whatsappUrl = `https://wa.me/${formattedPhoneNumber}?text=${message}`;
  //     window.open(whatsappUrl, '_blank');
  //   } else {
  //     console.warn("WhatsApp phone number not available");
  //   }
  // };

  // const handleEmployerLogin = () => navigate('/EmployerLogin');
  const handleHome = () => navigate('/');
  const handleAbout = () => navigate('/About');
  const handleWhyUs = () => navigate('/WhyUs');
  const handleBookNow = () => navigate('/BookNow');
  const handleFacilities = () => navigate('/OtherFacilities');
  const handleContact = () => navigate('/Contact');

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    // <div className="landing-nav">
    //   <div className="nav-LeftSide">

    //     <img src={logo} alt="Company Logo" className="company-logo" />

    //     <div className="contact-info">
    //       <img src={Call} alt="Call Icon" className="call-icon" />
    //       <p className="contact-number">
    //         <strong>+91 {WhatsAppNumber
    //          || 'N/A'}</strong>
    //       </p>

    //     </div>

    //     <div className="contact-info">
    //       <img src={mail} alt="Call Icon" className="call-icon" />
    //       <p className="contact-number">
    //         <strong> {mailId}</strong>
    //       </p>

    //     </div>
    //   </div>

    //   <div className="Social-media">
    //     <img src={location} alt="Location" title="Location" onClick={handleLocation} />
    //     <img src={whatsapp} alt="WhatsApp" title="WhatsApp" onClick={handleWhatsApp} />
    //     <img src={instagram} alt="Instagram" title="Instagram" onClick={handleInstagram} />
    //     <img src={FaceBooklogo} alt="logo" title="Facebook" onClick={handleFaceBook} />
    //   </div>

    //   <div className="dropdown">
    //     <button
    //       className="btn"
    //       type="button"
    //       id="dropdownMenuButton1"
    //       data-bs-toggle="dropdown"
    //       aria-expanded="false"
    //     >
    //       <img src={HamburgerMenu} alt="Menu" className="menu-icon" />
    //     </button>
    //     <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    //       <li><span className="dropdown-item" onClick={handleHome}>Home</span></li>
    //       <li><span className="dropdown-item" onClick={handleAbout}>About Us</span></li>
    //       <li><span className="dropdown-item" onClick={handleWhyUs}>Why Us</span></li>
    //       <li><span className="dropdown-item" onClick={handleBookNow}>Book Now</span></li>
    //       <li><span className="dropdown-item" onClick={handleFacilities}>Other Facilities</span></li>
    //       <li><span className="dropdown-item" onClick={handleContact}>Contact Us</span></li>
    //       {/* <li><span className="dropdown-item" onClick={handleEmployerLogin}>Employer Login</span></li> */}
    //     </ul>
    //   </div>
    // </div>

    <nav className="navbar navbar-expand-lg navbar-dark bg-success shadow-sm">

      
      <div className="container-fluid">
        {/* Left Side: Logo and Contact Info */}
        <div className="navbar-brand d-flex align-items-center" href="#">
          <img src={logo} alt="Company Logo" className="company-logo img-fluid me-2" style={{ height: "60px" }}
          onClick={handleLogoClick}
          
          />
        </div>

        <div>
          <div className="d-flex leftside">
            <div className="d-flex align-items-center me-3">
              <img src={Call} alt="Call Icon" className="call-icon me-2" style={{ width: "20px" }} />
              <span className="text-white fw-bold">+91 {WhatsAppNumber || "N/A"}</span>
            </div>

            <div className="d-flex align-items-center">
              <img src={mail} alt="Mail Icon" className="call-icon me-2" style={{ width: "20px" }} />
              <span className="text-white fw-bold">{mailId}</span>
            </div>
          </div>
        </div>


        {/* Navbar Toggler for Mobile */}
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Right Side: Social Media and Dropdown */}
        <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
          <div className="d-flex align-items-center social-media">
            <img src={location} alt="Location" title="Location" className="mx-2" style={{ width: "30px", cursor: "pointer" }} onClick={handleLocation} />
            <img src={whatsapp} alt="WhatsApp" title="WhatsApp" className="mx-2" style={{ width: "30px", cursor: "pointer" }} onClick={handleWhatsApp} />
            <img src={instagram} alt="Instagram" title="Instagram" className="mx-2" style={{ width: "30px", cursor: "pointer" }} onClick={handleInstagram} />
            <img src={FaceBooklogo} alt="Facebook" title="Facebook" className="mx-2" style={{ width: "30px", cursor: "pointer" }} onClick={handleFaceBook} />
          </div>

          {/* Dropdown Menu */}
          <ul className="navbar-nav ms-3">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle text-white fw-bold" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Menu
              </a>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                <li><span className="dropdown-item" onClick={handleHome}>Home</span></li>
                <li><span className="dropdown-item" onClick={handleAbout}>About Us</span></li>
                <li><span className="dropdown-item" onClick={handleWhyUs}>Why Us</span></li>
                <li><span className="dropdown-item" onClick={handleBookNow}>Book Now</span></li>
                <li><span className="dropdown-item" onClick={handleFacilities}>Other Facilities</span></li>
                <li><span className="dropdown-item" onClick={handleContact}>Contact Us</span></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default LandingNav;
