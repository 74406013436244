import Slider from 'react-slick';
import './PackagesCarousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Flight from '../../Assets/my-yathra/icons8-plane-24.png';
import Hotels from '../../Assets/my-yathra/icons8-hotel-24.png';
import Activities from '../../Assets/my-yathra/icons8-trekking-24.png';
import bed from '../../Assets/my-yathra/icons8-bed-24.png';
import VIETNAM from "../../Assets/my-yathra/WhatsApp Image 2025-02-09 at 9.59.11 PM.jpeg";
import manali from "../../Assets/my-yathra/manali.jpeg";
import hydrabad from "../../Assets/my-yathra/dc.jpg";
import jaipur from "../../Assets/my-yathra/AmberFortJaipur-1536x864.jpg";
import kerala from "../../Assets/my-yathra/alleppey-backwater-cruise.jpg";
import tailand from "../../Assets/my-yathra/WhatsApp Image 2025-04-08 at 1.12.42 PM.jpeg";
import malaysia from "../../Assets/my-yathra/malasai.jpeg";
import phuket from "../../Assets/my-yathra/phuket.jpeg"








import { useNavigate } from 'react-router-dom';


export const domesticTrips = [
  {
    id: 1,
    path: "MANALI-DELHI-AGRA",
    TripTitle: "MANALI DELHI AGRA  ",
    TripFile: manali,
    TripLocations: ["Manali", "Delhi", "Agra"],
    TripDuration: "6 Days / 5 Nights",
    Flights: "Included",
    HotelsTtype: "3-Star",
    HotelsStay: "7 Night ",
    AdditionalStay: "",
    Activities: ["solang Valley ", " Rohtang Pass ", " Hadimba Temple ", "Kullu views", "Taj Mahal ", "Agra Fort ", "Red fort", "Akshardham Temple", "india Gate", "Qutub Minar", "Lotus temple",],
    Food: ["05 Breakfast", "05 Lunch", "04 Dinner "],
    Inclusions: ["To and fro flight ticket at Cochin  with 07 kg cabin baggage only", "Alcazar show entry ticket", "Coral island speed boat Ticket", "Nong nooch Village lunch  ( Thai cultural show and Elephant show )", "Safari world Zoo and marine park ticket ", "Medium tiger photo shoot ticket", "Floting Market one way ", " City Tour ( Budha visit ) ", "Gems gallery ", "Elephant show ", "Dolphin show", "orang utan show"]

  },
  {
    id: 2,
    path: "HYDERABAD-PACKAGE",
    TripTitle: "HYDERABAD PACKAGE ",
    TripFile: hydrabad,
    TripLocations: "HYDERABAD",
    TripDuration: "4 Days / 3 Nights",
    Flights: "Included",
    HotelsTtype: "3-Star",
    HotelsStay: "3 Night ",
    // AdditionalStay: "01 Night Cruise Stay ",
    Activities: ["char minar", " salar jung museum ", "birla mandir", "ramoji film city", "golconda fort", "chowmahalla palce", "hussain sagar lake"],
    Food: ["03 Breakfast", "03 Lunch", "03 Dinner "],
    Inclusions: ["To and fro flight ticket", "Entry ticket's", "3 star hotel accommodation" ]

  },
  {
    id: 3,
    path: "DELHI-AGRA-JAIPUR",
    TripTitle: "DELHI AGRA JAIPUR",
    TripFile: jaipur,
    TripLocations: ["Jaipur", "Delhi", "Agra"],
    TripDuration: "06 Days / 05 Nights",
    Flights: "Included",
    HotelsTtype: "3-Star",
    HotelsStay: "5 Night ",
    AdditionalStay: "",
    Activities: ["amber palace","haw mahal","The city palace","Taj Mahal ", "Agra Fort ", "Red fort", "Akshardham Temple", "india Gate", "Qutub Minar", "Lotus temple",],
    Food: ["05 Breakfast", "04 Lunch", "04 Dinner "],
    Inclusions: ["To and fro flight ticket", "Entry ticket's", "3 star hotel accommodation" ]

  },
  {
    id: 4,
    path: "KERALA-PACKAGE",
    TripTitle: "KERALA PACKAGE ",
    TripFile: kerala,
    TripLocations: ["munnar","thekkady","alleppy","kovalam"],
    TripDuration: "6 Days / 5 Nights",
    Flights: "No Included",
    HotelsTtype: "3-Star",
    HotelsStay: "5 Night ",
    // AdditionalStay: "01 Night Cruise Stay ",
    Activities: ["eravikulam national park", " mattupetti dam ", " Tea gardens ", "periyar national park", "allepy house boat", "kovalam beach", "padmanabhaswamy temple ",],
    Food: ["05 Breakfast", "05 Lunch", "04 Dinner "],
    Inclusions: ["Entry ticket's", "3 star hotel accommodation" ]

  },

];

export const internationalTrips = [
  {
    id: 1,
    path: "VIETNAM-PACKAG",
    TripTitle: "VIETNAM STORY",
    TripFile: VIETNAM,
    TripLocations: "Ho chi minh , Danang, Hanoi",
    TripDuration: "8 Days / 7 Nights",
    Flights: "Included",
    HotelsTtype: "4-Star",
    HotelsStay: "6 Night ",
    AdditionalStay: "01 Night Cruise Stay ",
    Activities: ["Train Street", " One piller pagoda ", "Golden Bridge", "Bana Hills", "Cable car ", "Old Wineries", "Ling Ung pagoda", "Fantasy park", "Silk Museum", "Mekong Delta ", "Reunification Palace", "Central Post Ofice", "War Remnants Museum", "Cruise Dinner and Stay ", "Surprise Cave Visit "],
    Food: ["08 Breakfast", "07 Lunch", "07 Dinner "],
    Inclusions: ["To and fro flight ticket at Cochin", "6 night 4-star hotel stay ", "01 Night Cruise Stay ", "Ho chi minh - Da nang - Hanoi Flights Tickes",]

  },
  {
    id: 2,
    path: "Thailand",
    TripTitle: "VIBRANT THAILAND",
    TripFile: tailand,
    TripLocations: "Pattaya , Bangkok ",
    TripDuration: "05 Days / 04 Nights",
    Flights: "Included",
    HotelsTtype: "3-Star",
    HotelsStay: "04 Night ",
    AdditionalStay: "",
    Activities: ["Tiger Park", "Alcazar show", "Coral island", " Nong Nooch Village", " sanctuary of Truth", "Floating Market", "Safari World & Marine Park", "Bangkok city & Budha visit", "orang utan show", "Dolphin show", "Elephant show",
      "Gems gallery", "Floting Market one way ",],
    Food: ["05 Breakfast", "05 Lunch", "04 Dinner "],
    Inclusions: ["To and fro flight ticket at Cochin  with 07 kg cabin baggage only", "Alcazar show entry ticket", "Coral island speed boat Ticket", "Nong nooch Village  ( Thai cultural show and Elephant show )", "Safari world Zoo and marine park ticket ", "Medium tiger photo shoot ticket", "Floting Market one way ", " bangkok City Tour ( Budha visit ) ", "Gems gallery ", "Elephant show ", "Dolphin show", "orang utan show"]
  },

  {
    id: 3,
    path: "Malaysia-Malacca",
    TripTitle: "MALAYSIA AND MALACCA ",
    TripFile: malaysia,
    TripLocations: "Malaysia , Malacca ",
    TripDuration: "04 Days / 03 Nights",
    Flights: "Included",
    HotelsTtype: "3-Star",
    HotelsStay: "03 Night ",
    AdditionalStay: "",
    Activities: ["Kl Tower Observation Deck ", "Batu caves", " Twin Tower Photo shoot ", "Putrajaya photo shoot ", "KL aquarium ", "Kl city Tour [ King palace . National monument . Independence square ] ", "Malacca Tour with Boat ride ",],
    Food: ["04 Breakfast", "04 Lunch", "03 Dinner "],
    Inclusions: ["To and fro flight ticket at Cochin  with 07 kg cabin baggage only", "3-star hottel accommodation", "Meals", "entry tickets"],

  }, {
    id: 4,
    path: "PHUKET-KRABI ",
    TripTitle: "PHUKET & KRABI",
    TripFile: phuket,
    TripLocations: "PHUKET , KRABI ",
    TripDuration: "05 Days / 04 Nights",
    Flights: "Included",
    HotelsTtype: "3-Star",
    HotelsStay: "04 Night ",
    AdditionalStay: "",
    Activities: ["Half day krabi city tour", "Hrabi foyur islannd tour by lomg tail boat with luch box", "tiget kingdom", "carnival magical show", "Phi phi island tour by speed boat", "Half day phuket city tour",],
    Food: ["05 Breakfast", "05 Lunch", "04 Dinner "],
    Inclusions: ["To and fro flight ticket at Cochin  with 07 kg cabin baggage only", "3-star hottel accommodation", "Meals", "entry tickets"],
  },
  //  {
  //   id: 5,
  //   path: "Thailand",
  //   TripTitle: "Thailand",
  //   TripFile: "dubai.jpg",
  //   TripLocations: "Pattaya , Bangkok ",
  //   TripDuration: "05 Days / 04 Nights",
  //   Flights: "Included",
  //   HotelsTtype: "3-Star",
  //   HotelsStay: "00 Night ",
  //   AdditionalStay: "01 Night Cruise Stay ",
  //   Activities: ["Tiger Park", "Alcazar show", "Coral island", " Nong Nooch Village", " sanctuary of Truth", "Floating Market", "Safari World & Marine Park", "Bangkok city ", "Temple Tour", "orang utan show", "Dolphin show ", "Elephant show",
  //   "Gems gallery", " City Tour ( Budha visit ) ", "Floting Market one way ",],
  //   Food: [],
  //   Inclusions: [],
  // }, {
  //   id: 6,
  //   path: "Thailand",
  //   TripTitle: "Thailand",
  //   TripFile: "dubai.jpg",
  //   TripLocations: "Pattaya , Bangkok ",
  //   TripDuration: "05 Days / 04 Nights",
  //   Flights: "Included",
  //   HotelsTtype: "3-Star",
  //   HotelsStay: "00 Night ",
  //   AdditionalStay: "01 Night Cruise Stay ",
  //   Activities: ["Tiger Park", "Alcazar show", "Coral island", " Nong Nooch Village", " sanctuary of Truth", "Floating Market", "Safari World & Marine Park", "Bangkok city ", "Temple Tour", "orang utan show", "Dolphin show ", "Elephant show",
  //   "Gems gallery", " City Tour ( Budha visit ) ", "Floting Market one way ",],
  //   Food: [],
  //   Inclusions: [],
  // }, {
  //   id: 7,
  //   path: "Thailand",
  //   TripTitle: "Thailand",
  //   TripFile: "dubai.jpg",
  //   TripLocations: "Pattaya , Bangkok ",
  //   TripDuration: "05 Days / 04 Nights",
  //   Flights: "Included",
  //   HotelsTtype: "3-Star",
  //   HotelsStay: "00 Night ",
  //   AdditionalStay: "01 Night Cruise Stay ",
  //   Activities: ["Tiger Park", "Alcazar show", "Coral island", " Nong Nooch Village", " sanctuary of Truth", "Floating Market", "Safari World & Marine Park", "Bangkok city ", "Temple Tour", "orang utan show", "Dolphin show ", "Elephant show",
  //   "Gems gallery", " City Tour ( Budha visit ) ", "Floting Market one way ",],
  //   Food: [],
  //   Inclusions: [],
  // }, {
  //   id: 8,
  //   path: "Thailand",
  //   TripTitle: "Thailand",
  //   TripFile: "dubai.jpg",
  //   TripLocations: "Pattaya , Bangkok ",
  //   TripDuration: "05 Days / 04 Nights",
  //   Flights: "Included",
  //   HotelsTtype: "3-Star",
  //   HotelsStay: "00 Night ",
  //   AdditionalStay: "01 Night Cruise Stay ",
  //   Activities: ["Tiger Park", "Alcazar show", "Coral island", " Nong Nooch Village", " sanctuary of Truth", "Floating Market", "Safari World & Marine Park", "Bangkok city ", "Temple Tour", "orang utan show", "Dolphin show ", "Elephant show",
  //   "Gems gallery", " City Tour ( Budha visit ) ", "Floting Market one way ",],
  //   Food: [],
  //   Inclusions: [],
  // },
];







function PackagesCarousel() {

  const WhatsAppNumber = "9539771777"; // Add the phone number here if available
  const navigate = useNavigate();


  const handleTripClick = (path) => {
    navigate(`/trip/${path}`); // Redirects to the trip details page
  };



  const settings = {
    dots: true,
    infinite: true,
    speed: 1100,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1, infinite: true, dots: true } },
      { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };


  const handleWhatsApp = () => {
    const message = encodeURIComponent('Hello! I am contacting you regarding your trip.');
    const whatsappUrl = `https://wa.me/${WhatsAppNumber}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };



  return (
    <div>
      <div className="trips-details">
        <h5>Domestic Packages</h5>
        <Slider {...settings}>
          {domesticTrips.map((trip) => (
            <div key={trip.path} className="trip-card" onClick={() => handleTripClick(trip.path)}>
              <h5>{trip.TripTitle}</h5>

              {/* <img className="trip-image" src={`${BASE_URL}/UserFiles/${trip.TripFile}`} alt="Trip Image" /> */}
              <img className="trip-image" src={trip.TripFile} alt="Trip Image" />
              <div className="trip-details">
                <h5>{trip.TripLocations}</h5>
                <p>{trip.TripDuration}</p>
                <div className='Trip-data'>
                  <div className='trip-flight'><img src={Flight} alt="Flights" /><p>{trip.Flights}</p></div>
                  {/* <div className='trip-Hotels'><img src={Hotels} alt="Hotels" /><p>{trip.Hotels}</p></div> */}
                  <div className='trip-activities'><img src={Activities} alt="Activities" /><p>{trip.Activities.length}</p></div>
                </div>
                <div className="trip-price">
                  <div className='trip-Hotels'><img src={Hotels} alt="Hotels" /><p>{trip.HotelsTtype} </p></div>
                  <div className='trip-Hotels'><img src={bed} alt="Hotels" /><p>{trip.HotelsStay} </p></div>
                  {/* <div className="trip-price-normal"><img src={Rupee16} alt="Normal Price" /><p>{trip.TripAmount}/-</p></div> */}
                  {/* <div className="trip-price-discount"><img src={Rupee24} alt="Discounted Price" /><p>{trip.TripDiscountAmount}/-</p></div> */}
                </div>

                <button className="button_01" onClick={handleWhatsApp}>
                  Book Now
                </button>

                {/* <button className="button_01" onClick={() => handleWhatsApp(WhatsAppNumber)}>Book Now</button> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="trips-details">
        <h5>International Packages</h5>
        <Slider {...settings}>
          {internationalTrips.map((trip) => (
            <div key={trip.path} className="trip-card" onClick={() => handleTripClick(trip.path)}>
              <h5>{trip.TripTitle}</h5>
              <img className="trip-image" src={trip.TripFile} alt="Trip Image" />
              <div className="trip-details">
                <h5>{trip.TripLocations}</h5>
                <p>{trip.TripDuration}</p>
                <div className='Trip-data'>
                  <div className='trip-flight'><img src={Flight} alt="Flights" /><p>{trip.Flights}</p></div>
                  {/* <div className='trip-Hotels'><img src={Hotels} alt="Hotels" /><p>{trip.Hotels}</p></div> */}
                  <div className='trip-activities'><img src={Activities} alt="Activities" /><p>{trip.Activities.length}</p></div>
                </div>
                <div className="trip-price">
                  <div className='trip-Hotels'><img src={Hotels} alt="Hotels" /><p>{trip.HotelsTtype} </p></div>
                  <div className='trip-Hotels'><img src={bed} alt="Hotels" /><p>{trip.HotelsStay} </p></div>


                  {/* <div className="trip-price-normal"><img src={Rupee16} alt="Normal Price" /><p>{trip.TripAmount}/-</p></div> */}
                  {/* <div className="trip-price-discount"><img src={Rupee24} alt="Discounted Price" /><p>{trip.TripDiscountAmount}/-</p></div> */}

                </div>

                <div className='trip-Hotels'><p>{trip.AdditionalStay} </p></div>


                <button className="button_01" onClick={handleWhatsApp}>
                  Book Now
                </button>
                {/* <button className="button_01" onClick={() => handleWhatsApp(WhatsAppNumber)}>Book Now</button> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}


export default PackagesCarousel;
